html,
body {
  padding: 0;
  margin: 0;
  --chakra-fonts-body: 'Montserrat', 'Inter', 'Roboto', -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-family: var(--chakra-fonts-body) !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}

.dropzone {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 32px;
  border: dashed 2px rgb(187, 194, 205);
  border-radius: 8px;
  cursor: pointer;
  height: 96px;
  font-weight: 500;
  color:rgb(107, 112, 120);
  font-size: 14px;
}

.dropzone:hover {
  color:rgb(60, 63, 69);
  border: dashed 2px rgb(131, 137, 146);
}

.ag-theme-material * {
  font-family: var(--chakra-fonts-body) !important;
  font-size: 14px;
}

.table-tiny td {
  padding-left: 0px;
  padding-right: 0px;
}

.table-large td {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.table-small td{
  padding-left: 10px;
  padding-right: 10px;
}

.table-small th{
  padding-left: 10px;
  padding-right: 10px;
}

.option-md{
  padding-top: 20px;
  padding-bottom: 20px;
}

.ag-row .ag-cell {
  display: flex;
  align-items: center;
}

.grid-cell-centered {
  justify-content: center;
}
/* 
.ag-header-cell-label {
  justify-content: center;
} */
.ag-theme-quartz {
  --ag-foreground-color: rgb(126, 46, 132);
  --ag-background-color: rgb(249, 245, 227);
  --ag-header-foreground-color: rgb(204, 245, 172);
  --ag-header-background-color: rgb(209, 64, 129);
  --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
  --ag-header-column-resize-handle-color: rgb(126, 46, 132);

  --ag-font-size: 17px;
  --ag-font-family: monospace;
}